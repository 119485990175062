/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Psychiko est un roman grec paru sous forme de feuilleton dans la presse en 1928. L'auteur, Pétros K. Apostolidis alias Paul Nirvanas, nous transporte dans un Athènes bourgeois du début du XXe siècle. Ce roman est considéré comme étant le premier polar grec."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "L'auteur nous raconte l'étrange histoire d'un jeune grec riche nommé Nikos. Ce héros un peu loufoque élabore un plan dont le but est de se faire accuser d'un crime qu'il n'a pas commis. L'objectif : devenir célèbre dans la presse locale."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Cent ans après, cette histoire rocambolesque nous rappelle certains travers de notre époque comme la recherche de célébrité ou encore la polarisation de l'opinion publique. On peut aussi y voir une satire des enfants gâtés qui rencontrent souvent des difficultés à s'insérer dans la société et agissent parfois de manière inconsidérée."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Le style \"feuilleton\" de l'ouvrage peut paraître déroutant. La narration est rapide, parfois saccadée. Les titres de chapitres dévoilent une bonne partie de l'intrigue. Mais ce sont ces éléments qui donnent à Psychiko son originalité et sa fraîcheur. C'est un roman intéressant à lire, notamment de par son format court."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
